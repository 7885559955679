$(document).ready(function () {
  // Parsley.js
  Parsley.addValidator('minDate', {
    requirementType: 'string',
    validateString: function (value, minDateString) {
      let date = transpileStringToDate(value);
      let minDate = transpileStringToDate(minDateString);

      if (date && date < minDate) {
        return false;
      }

      return true;
    }
  });

  Parsley.addValidator('maxDate', {
    requirementType: 'string',
    validateString: function (value, maxDateString) {
      let date = transpileStringToDate(value);
      let maxDate = transpileStringToDate(maxDateString);

      if (date && date > maxDate) {
        return false;
      }

      return true;
    }
  });

  Parsley.addValidator('requiredonradio', {
    requirementType: 'string',
    validateString: function (value, selector) {
      if ($(selector).is(':checked') && value === '') {
        return false;
      }
      return true;
    }
  });

  Parsley.addValidator('requiredoncheckbox', {
    requirementType: 'string',
    validateString: function (value, selector) {
      if ($(selector).is(':checked') && value === '') {
        return false;
      }
      return true;
    }
  });

  Parsley.addValidator('requiredonselectslug', {
    requirementType: 'string',
    validateString: function (value, selector) {
      let selected = $("option[data-slug$=" + selector + "]").is(':selected')
      if (selected && value === '') {
        return false;
      }
      return true;
    }
  });

  Parsley.addValidator('profileDescription', {
    requirementType: 'string',
    validateString: function (value, _, parsleyInstance) {
      const descriptionField = $(parsleyInstance.element),
          maxCharsDuplicated = descriptionField.data('max-chars-duplicated'),
          maxWordLen = descriptionField.data('max-word-len'),
          wordLenRegex = new RegExp("^\\W*(?:\\w{1," + maxWordLen + "}(?:\\W+|$))*$", 'g'),
          duplicatedSymbolsRegex = new RegExp('^(?!.*(.)\\1{' + maxCharsDuplicated + ',}).+$', 'gs');
      return wordLenRegex.test(value) && duplicatedSymbolsRegex.test(value);
    }
  });

  Parsley.options.errorsWrapper =
    '<ul class="list-unstyled u-no-margin"></div>';
  Parsley.options.errorsContainer = function (Field) {
    let $formGroup = Field.$element.closest('.js-form-group');
    let $errorContainer = $formGroup.find('.help-block.error');

    $errorContainer.empty();

    return $errorContainer;
  };
  Parsley.options.errorClass = 'has-error';
  Parsley.options.successClass = '';
  Parsley.options.classHandler = function (el) {
    const $formGroup = el.$element.closest('.js-form-group');
    return $([...$formGroup, ...$formGroup.find('.input-field')]);
  };

  $('.js-form-parsley').parsley();

  function transpileStringToDate (value) {
    let splittedDate = value.split('.');
    let day;
    let month;
    let year;
    let date;

    if (splittedDate && splittedDate.length === 3) {
      splittedDate = value.split('.');
      day = splittedDate[0];
      month = splittedDate[1];
      year = splittedDate[2];

      date = new Date(year, month, day);

      return date;
    }

    return null;
  }
});
